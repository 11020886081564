import React, { useState } from 'react';
import {
  InputGroup,
  FormControl,
  Image,
  Modal,
  Media,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import configuration from "react-global-configuration";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { translate, t } from 'react-multi-lang';
import { useSelector } from 'react-redux';

const WalletAmountSec = (props) => {
  const [error, setError] = useState('');
  const transfeeraPayment = useSelector(state => state.wallet.transfeeraPayment)
  let env = configuration.get("configData.PAYPAL_MODE"); // you can set here to 'production' for production
  let currency = "USD"; // or you can set this value from your props or state

  const client = {
    sandbox: configuration.get("configData.PAYPAL_ID"),
    production: configuration.get("configData.PAYPAL_ID"),
  };

  const validateAmount = (e) => {
    props.setAmount(e.target.value)
    if (e.target.value < 10) {
      setError(t("add_wallet_min_amount"));
    }
    else
      setError('');
  };

  return (
    <>
      <div className="wallet-modal-details mt-5">
        <h4 className="payment-modal-title">{t("add_wallet_token")}</h4>
        <p>{t("looking_beatiful_para")}</p>
        <Form onSubmit={props.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control type="text"
              value={props.amount}
              min="50"
              placeholder={t("add_wallet_placeholder")}
              onChange={(e) => validateAmount(e)}
            />
          </Form.Group>
          {error && <div className='error-msg'>{error}</div>}
          <div className="wallet-account-balance mt-5 mb-5">
            {t("amount")} ({configuration.get("configData.token_amount")} * {props.amount}) = {configuration.get("configData.currency")}{configuration.get("configData.token_amount") * props.amount}
          </div>
          <div className="add-card-btn">
            {props.paymentType === "PAYPAL" ?
              <div className={props.amount >= 10 ? null : "disabled"} >
                <PaypalExpressBtn
                  env={env}
                  client={client}
                  currency={currency}
                  total={props.amount}
                  onError={props.paypalOnError}
                  onSuccess={props.paypalOnSuccess}
                  onCancel={props.paypalOnCancel}
                />
              </div>

              : <Button type="submit"
                disabled={props.amount && props.amount >= 10 ? false : true || props.buttonDisable || transfeeraPayment.buttonDisable}
              >{
                  props.loadingButtonContent || transfeeraPayment.loadingButtonContent ?
                    props.loadingButtonContent || transfeeraPayment.loadingButtonContent
                    : t("add")
                }</Button>
            }
          </div>
        </Form>
      </div>
    </>
  )

}

export default translate(WalletAmountSec);