import React, { useState } from 'react';
import { translate, t } from 'react-multi-lang';

const TransfeeraQrCode = (props) => {
    return (
        <>
            <div className="mb-5 back" onClick={() => props.setTransfeeraQrCode(null)}>
                <i className="fas fa-arrow-left mr-2"></i> Back
            </div>
            <div className='qr-image-container'>

                <img
                    src={
                        props.transfeeraQrCode
                    }
                    className="qr-code-transfeera"
                    alt="Transfeera Qr code"
                />
            </div>
        </>

    )
}

export default translate(TransfeeraQrCode); 
